import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoModule, provideTranslocoScope } from '@jsverse/transloco';
import { ErrorPageComponent } from '@cca/ui';

@Component({
  selector: 'cca-sequence-error',
  imports: [TranslocoModule, ErrorPageComponent],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('sequence')],
})
export class SequenceErrorComponent {
  readonly errorMessage = input<string | null>(null);
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  readonly title = input<string | null>();
  // TODO: Skipped for migration because:
  //  This input is used in a control flow expression (e.g. `@if` or `*ngIf`)
  //  and migrating would break narrowing currently.
  readonly paragraph = input<string | null>();
  readonly showButtons = input<boolean | null>();
  readonly src = input('assets/images/booking/confirmation-vector.svg');

  // error message could be a json object, since it it assigned from a httpResponse. Making a check here to make sure we only display it as a string error message
  get showErrorMessage() {
    return typeof this.errorMessage() === 'string';
  }
}
