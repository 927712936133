<ng-container *transloco="let t; read: 'sequence.error'">
  @let title = this.title();
  @if (showErrorMessage) {
    <cca-error-page
      class="block"
      [showButtons]="showButtons()"
      [title]="title ? title : t('general.title')"
      [paragraph]="errorMessage()"
      [src]="src()"
    />
  } @else {
    @let paragraph = this.paragraph();
    <cca-error-page
      class="block"
      [showButtons]="showButtons()"
      [title]="title ? title : t('general.title')"
      [paragraph]="paragraph ? paragraph : t('resolveError.paragraph')"
      [src]="src()"
    />
  }
</ng-container>
